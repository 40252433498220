<template>
  <div v-loading="loading">
    <div v-if="programList.length > 0">
      <el-button class="ruge-singleBtn-s" size="mini" @click="fullChoice()">
        {{ $t("commons.fullChoice") }}
      </el-button>
      <el-button class="ruge-singleBtn-s" size="mini" @click="unFullChoice()">
        {{ $t("commons.unFullChoice") }}
      </el-button>
      <el-button
        class="ruge-singleBtn-s"
        type="primary"
        size="mini"
        @click="grantProgram()"
      >
        {{ $t("commons.save") }}
      </el-button>
    </div>
    <el-row>
      <template v-if="programList.length > 0">
        <el-col
          class="ruge-cards"
          :span="5"
          v-for="(program, index) in programList"
          :key="program.programId"
          :offset="index % 4 == 0 ? 0 : 1"
        >
          <el-card @click.native="choiceCard(program)" :class="program.choice">
            <div class="ruge-card-textBox" :style="elCardIcon">
              <span class="ruge-card-text">{{ program.programName }}</span>
            </div>
            <img class="elCardActive" :src="elCardActiveSrc" />
          </el-card>
        </el-col>
      </template>
      <template v-else>
        <div class="no-data">{{ $t("commons.noData") }}</div>
      </template>
    </el-row>
  </div>
</template>

<script>
let manager = require("@/api/ruge/security/program");

export default {
  data() {
    return {
      loading: true,
      programList: [],
      elCardIcon: {
        background:
          "url(" +
          require("@/assets/images/lineIcon.png") +
          ") center right no-repeat",
      },
      elCardActiveSrc: require("@/assets/images/el-card-active.png"),
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userAccount() {
      return this.currentRow.userAccount;
    },
  },
  watch: {
    userAccount() {
      this.getAllProgram();
    },
  },
  created() {
    this.getAllProgram();
  },
  methods: {
    getAllProgram() {
      this.loading = true;
      manager
        ._tenant_getCurrentUserProgramList(this.currentRow)
        .then((response) => {
          let hasProgram = new Map();
          if (Response) {
            response.map((m) => {
              hasProgram.set(m.programId, m);
            });
          }
          manager
            ._tenant_getProgramList()
            .then((resp) => {
              resp.map((r) => {
                r.programName =
                  r.programName.length > 15
                    ? r.programName.substring(0, 15) + "..."
                    : r.programName;
                let cr = hasProgram.get(r.programId);
                if (cr) {
                  this.$set(r, "choice", "choice");
                } else {
                  this.$set(r, "choice", "");
                }
              });
              this.programList = resp;
            })
            .catch(() => {});
        })
        .finally(() => {
          this.loading = false;
        });
    },
    choiceCard(program) {
      if (program.choice) {
        this.$set(program, "choice", "");
      } else {
        this.$set(program, "choice", "choice");
      }
    },
    //权限全选
    fullChoice() {
      this.programList.map((m) => {
        this.$set(m, "choice", "choice");
      });
    },
    //取消权限全选
    unFullChoice() {
      this.programList.map((m) => {
        this.$set(m, "choice", "");
      });
    },
    grantProgram() {
      let temp = this.programList.filter((m) => {
        return m.choice;
      });
      let programs = new Array();
      temp.map((m) => {
        programs.push({ programId: m.programId, programName: m.programName });
      });

      manager
        ._tenant_grantProgram(
          Object.assign({}, this.currentRow, { programList: programs })
        )
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((e) => {
          console.log(`保存失败，原因 => ${e}`);
        });
    },
  },
};
</script>
